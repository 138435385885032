import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import ArticlesGrid from "../components/articles-grid";
import Seo from "../components/seo";
import Headings from "../components/headings";

const IndexPage = () => {
  const { allStrapiArticle, strapiGlobal } = useStaticQuery(graphql`
    query {
      allStrapiArticle {
        nodes {
          ...ArticleCard
        }
      }
      strapiGlobal {
        siteName
        siteDescription
      }
    }
  `);

  const sortedArticles = allStrapiArticle.nodes.sort(
    (a, b) => a.category.priority - b.category.priority
  );

  return (
    <Layout>
      <Seo seo={{ metaTitle: "Главная" }} />
      <Headings
        title={strapiGlobal.siteName}
        description={strapiGlobal.siteDescription}
      />
      <main>
        <ArticlesGrid articles={sortedArticles} />
      </main>
    </Layout>
  );
};

export default IndexPage;
